<template>
  <SectionSplit class="SigEnerfip" src="../../assets/portfolio-solar.jpg">
    <h3>
      {{ $t('title') }}
    </h3>
    <p v-for="(paragraph, index) in $t('paragraphs')" :key="index">
      {{ paragraph }}
    </p>
    <div class="videoBox">
      <VueDPlayer ref="player" :options="videoOptions" />
    </div>
  </SectionSplit>
</template>

<script>
import 'vue-dplayer/dist/vue-dplayer.css'
import SectionSplit from 'views/components/SectionSplit'
import VueDPlayer from 'vue-dplayer'
import { getters } from 'views/utils'

export default {
  components: { SectionSplit, VueDPlayer },
  computed: {
    videoOptions() {
      return {
        video: {
          url: this.locale === 'es' ? 'https://suninvestmentgroup.com/assets/enerfip/SigCrouwdfundingEs.mp4' : 'https://suninvestmentgroup.com/assets/enerfip/SigCrouwdfundingEn.mp4',
          pic: 'https://sig-web-uploads.s3.eu-central-1.amazonaws.com/stopframe_6bdbbd66cd.jpeg',
        },
        autoplay: false,
        contextmenu: [{
          text: 'GitHub',
          link: 'https://github.com/MoePlayer/vue-dplayer',
        }],
      }
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
.SingEnerfip {
  .videoBox {
    display: flex;
    justify-content: center;
  }

  .dplayer {
    height: 18rem;
    width: 100%;
    @include md {
      height: 25rem;
      width: 100%;
    }
    @include xl {
      height: 25rem;
      width: 50rem;
    }
  }

  .dplayer-menu.dplayer-menu-show {
    display: none;
  }
}
</style>

<i18n>
{
  "en": {
    "title": "SIG and Enerfip Unveil Crowdfunding Campaign",
    "paragraphs": [
      "In the mid of January, Sun Investment Group together with Enerfip unveiled a strategic crowdfunding campaign dedicated to advancing sustainable energy initiatives across Europe. Within the framework of this public bond, SIG aims to attract up to 5 million euros in investments.",
      "This crowdfunding campaign aims to finance the development of four PV projects in Italy known as “Cancello e Arnone 1”, “Cancello e Arnone 2”, “Castelvolturno 2”, “Mondragone”, with a total capacity of 135 MW."
    ]
  },
  "es": {
    "title": "Sig y Enerfip lanzan una campaña de Crowdfunding",
    "paragraphs": [
      "Sun Investment Group ha lanzado junto con Enerfip, una campaña estratégica de crowdfunding dedicada a promover iniciativas de energía sostenible en toda Europa. Desde el 25 de enero, en el marco de este bono público, SIG pretende captar hasta 5 millones de euros en inversiones en dos tramos.",
      "Esta campaña de crowdfunding tiene como objetivo financiar el desarrollo de cuatro proyectos fotovoltaicos en Italia cuyos nombres son \"Cancello e Arnone 1\", \"Cancello e Arnone 2\", \"Castelvolturno 2\" y \"Mondragone\", con una potencia total de 135 MW."
    ]
  }
}
</i18n>
