<template>
  <section class="InvestmentDetails">
    <Wrap class="lg">
      <h3>
        {{ $t('title') }}
      </h3>

      <div class="details-wrapper">
        <!-- <table class="details">
          <tr>
            <th v-for="(header, index) in $t('headers')" :key="index">
              {{ header }}
            </th>
          </tr>
          <tr>
            <td v-for="(value, index) in $t('values')" :key="index">
              {{ value }}
            </td>
          </tr>
        </table> -->
        <table class="details">
          <tr v-for="(detail, index) in $t('details')" :key="index">
            <th>{{ detail[0] }}</th>
            <td>{{ detail[1] }}</td>
          </tr>
        </table>
      </div>
    </Wrap>
  </section>
</template>

<script>
// import 'vue-dplayer/dist/vue-dplayer.css'
// import VueDPlayer from 'vue-dplayer'

export default {
  // components: { VueDPlayer },
  computed: {
    videoOptions() {
      return {
        video: {
          url: this.locale === 'es' ? 'https://suninvestmentgroup.com/assets/enerfip/SigCrouwdfundingEs.mp4' : 'https://suninvestmentgroup.com/assets/enerfip/SigCrouwdfundingEn.mp4',
          pic: 'https://sig-web-uploads.s3.eu-central-1.amazonaws.com/stopframe_6bdbbd66cd.jpeg',
        },
        autoplay: false,
        contextmenu: [{
          text: 'GitHub',
          link: 'https://github.com/MoePlayer/vue-dplayer',
        }],
      }
    },
  },
}
</script>

<style lang="scss">
.InvestmentDetails {
  h3 {
    text-align: center;
  }

  .videoBox {
    display: flex;
    justify-content: center;
  }

  .dplayer {
    height: 18rem;
    width: 100%;
    @include md {
      height: 25rem;
      width: 100%;
    }
    @include xl {
      height: 25rem;
      width: 50rem;
    }
  }

  .dplayer-menu.dplayer-menu-show {
    display: none;
  }

  .details-wrapper {
    display: flex;
    justify-content: center;
    padding: 3rem 0;

    table.details {
      min-width: 50vw;

      th, td {
        border: 2px solid $main;
        padding: 1.5rem;
        text-align: center;
        @include lg {
          font-size: $h5;
        }
      }

      th {
        width: 65%;
        @include lg {
          width: 75%;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "title": "What are the investment characteristics?",
    "details": [
      [
        "Fixed interest rate",
        "9% annually"
      ],
      [
        "Type of Investment",
        "Bonds / Simple Obligations"
      ],
      [
        "Investment Term",
        "24 months"
      ],
      [
        "Interest payment",
        "Annual payment"
      ],
      [
        "Minimal investment amount",
        "10 euros"
      ]
    ]
  },
  "es": {
    "title": "¿Cuáles son las características de esta inversión?",
    "details": [
      [
        "Tipo de interés fijo",
        "9% anual"
      ],
      [
        "Tipo de Inversión",
        "Bonos / Obligaciones Simples"
      ],
      [
        "Plazo de inversión",
        "24 meses"
      ],
      [
        "Pago de intereses",
        "Pago anual"
      ],
      [
        "Importe mínimo de inversión",
        "10 euros"
      ]
    ]
  }
}
</i18n>
