<template>
  <div class="Accordion">
    <div class="header">
      <div class="title" @click="isOpen = !isOpen" @keypress.enter="isOpen">
        <h4>{{ title }}</h4>
        <div v-if="meta" class="meta">
          {{ meta }}
        </div>
      </div>
      <BtnRound data-cy="expand" @click="isOpen = !isOpen">
        <Icon :name="isOpen ? 'cross' : 'plus'" />
      </BtnRound>
    </div>
    <transition name="slide-fade-up">
      <div v-if="isOpen" class="content">
        <Markdown v-if="markdown" :markdown="markdown" />
        <slot />
      </div>
    </transition>
    <div
      v-if="isOpen"
      class="close"
      @click="close"
      @keypress.enter="close"
      @keypress.esc="close">
      {{ $t('close') }}
    </div>
  </div>
</template>

<script>
import BtnRound from 'views/components/BtnRound'
import { scrollTo } from '../../router/smoothScroll'

const Markdown = () => import(/* webpackChunkName: 'showdown' */ 'views/components/Markdown')

export default {
  components: { BtnRound, Markdown },
  props: {
    title: { type: String, required: true },
    meta: { type: String },
    markdown: { type: String },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    open() {
      this.isOpen = true
      scrollTo(this.$el)
    },
    close() {
      this.isOpen = false
      scrollTo(this.$el)
    },
  },
}
</script>

<style lang="scss">
$_text-light: $text-color-light;

.Accordion {
  border-bottom: 1px solid $border-color-light;

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 3rem;
    padding-top: 3rem;

    .title h4 {
      cursor: pointer;
      font-size: $h35;
    }

    .meta {
      color: $_text-light;
      margin-top: 0.75rem;
      opacity: 0.9;
    }

    .BtnRound {
      float: right;
      margin-left: 0.5rem;
    }
  }

  .content {
    color: $_text-light;
    font-size: $h6;
    line-height: 2rem;
    max-width: 660px;

    h1,
    h2,
    h3 {
      font-size: $h35;
      line-height: 4rem;
    }

    h4 {
      font-size: $h4;
      line-height: 3rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $text-color;
      margin: 3rem 0 1rem;
    }

    ul {
      margin-left: 1rem;
    }

    p {
      margin-bottom: 1rem;
    }
  }

  .close {
    cursor: pointer;
    font-weight: $semibold;
    margin-top: 3rem;
    padding-bottom: 3rem;
    text-align: right;
  }

  &:first-of-type {
    .header {
      padding-top: 0;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}
</style>

<i18n>
{
  "de": {
    "close": "Schließen"
  },
  "en": {
    "close": "Close"
  },
  "it": {
    "close": "Chiudere"
  },
  "lt": {
    "close": "Uždaryti"
  },
  "pl": {
    "close": "Zamknij"
  },
  "es": {
    "close": "Cerca"
  }
}
</i18n>
