<template>
  <SectionSplit class="reverse" src="../../assets/landing-enerfip/businesses.jpg">
    <h3>
      {{ $t('title') }}
    </h3>
    <p v-for="(paragraph, index) in $t('paragraphs')" :key="index" v-html="paragraph" />
  </SectionSplit>
</template>

<script>
import SectionSplit from 'views/components/SectionSplit'

export default {
  components: { SectionSplit },
}
</script>

<style lang="scss">
.Btn.xs {
  margin-top: 1rem;
}
</style>

<i18n>
{
  "en": {
    "title": "What should I do in order to invest?",
    "paragraphs": [
      "Start with registering on the Enerfip website using these links:<br><a href=\"https://fr.enerfip.eu/placer-son-argent/\" class=\"Btn xs\" target=\"_blank\">French</a> <a href=\"https://es.enerfip.eu/placer-son-argent/\" class=\"Btn xs\" target=\"_blank\">Spanish</a>",
      "We advise prompt action due to the variable registration duration, typically ranging between 24 to 48 hours.",
      "Upon successful registration, access the project through these links:<br><a href=\"https://fr.enerfip.eu/placer-son-argent/investissement-solaire/sun-investment-group-italia/\" class=\"Btn xs\" target=\"_blank\">French</a> <a href=\"https://es.enerfip.eu/invertir/investissement-solaire/sun-investment-group-italia/\" class=\"Btn xs\" target=\"_blank\">Spanish</a>",
      "Subsequently, select the \"Recevoir une alerte\" option to receive updates on the project's progress.",
      "Subscribe, once the subscription period is open. 25/01/2024.",
      "Payment for the subscription can be executed before, during, or within the ten days (10) subsequent to the subscription process, utilizing the payment methods (credit card, wire transfer) provided on the platform.",
      "For any questions, write to us at this email: <a href=\"mailto:investisseurs@enerfip.eu\" target=\"_blank\">investisseurs@enerfip.eu</a>"
    ]
  },
    "es": {
    "title": "¿Qué debo hacer para invertir?",
    "paragraphs": [
      "Comience registrándose en la página web de Enerfip a través de este enlace:<br><a href=\"https://fr.enerfip.eu/placer-son-argent/\" class=\"Btn xs\" target=\"_blank\">Francesa</a> <a href=\"https://es.enerfip.eu/placer-son-argent/\" class=\"Btn xs\" target=\"_blank\">Español</a>",
      "Aconsejamos registrarse con antelación debido a que la duración del registro suele oscilar entre 24 y 48 horas.",
      "Una vez que el registro se haya completado correctamente, acceda al proyecto a través de este enlace:<br><a href=\"https://fr.enerfip.eu/placer-son-argent/investissement-solaire/sun-investment-group-italia/\" class=\"Btn xs\" target=\"_blank\">Francesa</a> <a href=\"https://es.enerfip.eu/invertir/investissement-solaire/sun-investment-group-italia/\" class=\"Btn xs\" target=\"_blank\">Español</a>",
      "Posteriormente, seleccione la opción \"Recevoir une alerte\" para recibir actualizaciones sobre el progreso del proyecto.",
      "Una vez abierto el periodo de suscripción (25/01/2024), suscríbete!!",
      "El pago de la suscripción puede ejecutarse antes, durante o dentro de los diez (10) días posteriores al proceso de suscripción, utilizando los métodos de pago (tarjeta de crédito o transferencia bancaria) proporcionados en la plataforma.",
      "Para cualquier duda, escribe a este correo: <a href=\"mailto:investisseurs@enerfip.eu\" target=\"_blank\">investisseurs@enerfip.eu</a>"
    ]
  }
}
</i18n>
